import React, { FC, useEffect } from 'react';
import Login from 'pages/Login';
import Home from 'pages/Home';
import useAuth from 'hooks/useAuth';
import {
    collection,
    doc,
    DocumentReference,
    getDoc,
    getFirestore,
    onSnapshot,
} from 'firebase/firestore';
import { useSettings } from 'hooks/SettingsContext';
import { Clinic, Settings, SurveyQuestion } from 'types';
import Loading from 'pages/Loading';
import { useClinic } from 'hooks/ClinicContext';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import NotFound from 'pages/NotFound';
import { useSurvey } from 'hooks/SurveyContext';

const App: FC = () => {
    const { authUser, clinicId, isLoading } = useAuth();
    const { setSettings, settings } = useSettings();
    const { setSelectedClinic, setClinicsInOrganization } = useClinic();
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const { setSurveyQuestions } = useSurvey();

    useEffect(() => {
        if (!authUser || !clinicId) return;
        const db = getFirestore();
        const docRef = doc(db, 'clinicSettinigs', clinicId);
        const unsubSettings = onSnapshot(docRef, (snapshotSettings) => {
            const settingsData = snapshotSettings.data() as Settings;
            setSettings(settingsData);
        });
        // eslint-disable-next-line consistent-return
        return unsubSettings;
    }, [authUser, clinicId, setSettings]);

    useEffect(() => {
        if (!authUser || !clinicId) return;
        const db = getFirestore();
        const colRef = collection(db, 'clinics');
        const docRef = doc(colRef, clinicId);

        const unsubClinics = onSnapshot(colRef, async (snapshotClinic) => {
            const docSnapShot = await getDoc(docRef);
            const clinic = docSnapShot.data() as Clinic;
            const clinics = snapshotClinic.docs
                .map((clinic) => {
                    return { id: clinic.id, ...clinic.data() } as Clinic;
                })
                .filter((elem) => clinic.clinicsInOrganization.includes(elem.id));

            setClinicsInOrganization(clinics);
            const currentClinic = clinics.find((clinic) => clinic.id === clinicId);
            if (!currentClinic) return;
            setSelectedClinic(currentClinic);
        });
        // eslint-disable-next-line consistent-return
        return unsubClinics;
    }, [authUser, clinicId, setSelectedClinic, setClinicsInOrganization]);

    useEffect(() => {
        if (!settings) return;
        const getQuestions = async (docRefs: DocumentReference[]) => {
            const questionPromises = docRefs.map((docRef) =>
                getDoc(docRef).then((docSnap) => {
                    return { id: docSnap.id, ...docSnap.data() } as SurveyQuestion;
                })
            );
            const questionsDocs = await Promise.all(questionPromises);
            return questionsDocs;
        };
        // eslint-disable-next-line promise/catch-or-return
        getQuestions(settings.features.surveyQuestions).then(setSurveyQuestions);
    }, [settings, setSurveyQuestions]);

    useEffect(() => {
        if (!isLoading && !authUser && pathname !== '/login') {
            navigate('/login');
        }
    }, [authUser, isLoading, navigate, pathname]);

    return !isLoading ? (
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="*" element={<NotFound />} />
        </Routes>
    ) : (
        <Loading />
    );
};

export default App;
