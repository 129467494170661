import React, { FC, ReactElement } from 'react';
import styles from './header.module.scss';
import Dropdown from 'components/Dropdown';
import { useTranslation } from 'react-i18next';
import { useRemoveModal } from 'hooks/RemoveModalContext';
import { analytics, signOutUser } from 'firebase-service';
import { useSettings } from 'hooks/SettingsContext';
import { logEvent } from 'firebase/analytics';
import useAuth from 'hooks/useAuth';
const PLACEHOLDER_LOGO_BLUE = 'assets/logo_here_blue.svg';
const LOGOUT_ICON = 'assets/logout_icon.svg';

const Header: FC = (): ReactElement => {
    const { t } = useTranslation();
    const { isRemoveModalOpen } = useRemoveModal();
    const { settings } = useSettings();
    const { authUser } = useAuth();
    return (
        <div className={`${styles.wrapper} ${isRemoveModalOpen ? styles.blur : ''}`}>
            <img
                src={settings?.logo ?? PLACEHOLDER_LOGO_BLUE}
                alt="logo"
                className={styles.headerLogo}
            />
            <div className={styles.headerToolbar}>
                <Dropdown />
                <div
                    onClick={async () => {
                        await signOutUser();
                        logEvent(analytics, 'logout', { user: authUser?.email });
                    }}
                    className={styles.headerToolbarLogoutButton}
                >
                    {t('logout')}
                    <img
                        className={styles.headerToolbarLogoutButtonIcon}
                        src={LOGOUT_ICON}
                        alt=""
                    />
                </div>
            </div>
        </div>
    );
};
export default Header;
