import { useEffect, useState } from 'react';
import { authentication } from 'firebase-service';
import { User } from 'firebase/auth';

const useAuth = (): { authUser: User | null; isLoading: boolean; clinicId: string | null } => {
    const [authUser, setAuthUser] = useState<User | null>(null);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const unlisten = authentication.onAuthStateChanged((user) => {
            setIsLoading(true);
            setAuthUser(user);
            setIsLoading(false);
        });
        return () => {
            unlisten();
        };
    }, []);

    return { authUser, isLoading, clinicId: authUser?.email?.split('@')[0] ?? null };
};

export default useAuth;
