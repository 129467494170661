import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import './i18n';
import { SettingsProvider } from 'hooks/SettingsContext';
import { ClinicProvider } from 'hooks/ClinicContext';
import { RemoveModalProvider } from 'hooks/RemoveModalContext';
import './index.scss';
import { SurveyProvider } from 'hooks/SurveyContext';

const container = document.getElementById('hyperstack-ehealth-doctor') as Element;
const root = createRoot(container);

root.render(
    <Router>
        <SettingsProvider>
            <SurveyProvider>
                <RemoveModalProvider>
                    <ClinicProvider>
                        <App />
                    </ClinicProvider>
                </RemoveModalProvider>
            </SurveyProvider>
        </SettingsProvider>
    </Router>
);
