import React, { ReactElement, useEffect, useRef, useState, FC, useMemo } from 'react';
import styles from './dropdown.module.scss';
import { useClinic } from 'hooks/ClinicContext';
import { logEvent } from 'firebase/analytics';
import { analytics } from 'firebase-service';
const DOWN_ICON = 'assets/down_icon.svg';

const Dropdown: FC = (): ReactElement => {
    const { selectedClinic, setSelectedClinic, clinicsInOrganization } = useClinic();
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const selectedValueRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleClickOutside = (e: MouseEvent) => {
            if (selectedValueRef.current && !selectedValueRef.current.contains(e.target as Node)) {
                setIsDropdownOpen(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const options = useMemo(() => {
        return clinicsInOrganization
            ?.filter((clinic) => clinic !== selectedClinic)
            .map((clinic) => (
                <div
                    key={clinic.id}
                    className={styles.dropdownOption}
                    onClick={() => {
                        setSelectedClinic(clinic);
                        setIsDropdownOpen(false);
                        logEvent(analytics, 'updated_viewing_current_clinic', clinic);
                    }}
                >
                    {clinic.name}
                </div>
            ));
    }, [selectedClinic, clinicsInOrganization, setSelectedClinic, setIsDropdownOpen]);

    return (
        <div className={styles.wrapper}>
            <div ref={selectedValueRef} className={styles.dropdown}>
                <div
                    className={`${styles.dropdownOption} ${styles.dropdownSelectedOption} ${
                        isDropdownOpen ? styles.dropdownSelectedOptionArrowRotate : ''
                    }`}
                    onClick={() => setIsDropdownOpen((isOpen) => !isOpen)}
                >
                    {selectedClinic?.name}
                    <img src={DOWN_ICON} alt="" />
                </div>
                {isDropdownOpen && <hr />}
                {isDropdownOpen && options}
            </div>
        </div>
    );
};
export default Dropdown;
