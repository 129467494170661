import SwimlaneItem from 'components/SwimlaneItem';
import React, { FC } from 'react';
import { Appointment } from 'types';

type SwimlaneProps = {
    appointments?: Appointment[];
    handleCopyPopup?: () => void;
};

const Swimlane: FC<SwimlaneProps> = ({ appointments, handleCopyPopup }) => {
    return (
        <>
            {appointments?.map((appointment) => (
                <SwimlaneItem
                    handleCopyPopup={handleCopyPopup}
                    appointment={appointment}
                    key={appointment.id}
                />
            ))}
        </>
    );
};
export default Swimlane;
